// import PropTypes from "prop-types"
import React from "react"
import Image from "./Image"

import DE from "../svgs/flags-iso/DE.svg"
import AT from "../svgs/flags-iso/AT.svg"
import CH from "../svgs/flags-iso/CH.svg"

import { Facebook, Star } from "react-feather"
import Rating from "react-rating"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const Footer = ({ classList, type }) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiRating(filter: { rating: { ne: null } }) {
        edges {
          node {
            id
            rating
          }
        }
      }
    }
  `)

  const year = new Date().getFullYear()

  const ratings = data.allStrapiRating.edges
  const sumRatings = ratings.reduce((accumulator, currentValue) => {
    return (
      (typeof accumulator === "object"
        ? accumulator.node.rating
        : accumulator) + currentValue.node.rating
    )
  })
  const avgRatings = sumRatings / ratings.length || 0
  return (
    <footer className="pt-8 pt-md-11 bg-white">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-3">
            <Link to="/de/">
              <Image
                src="/assets/logo/maklerzentrale_fw.png"
                loadFrom="logo"
                style={{ height: "auto", width: "200px" }}
                className="footer-brand img-fluid mb-2"
                alt="Maklerzentrale"
              />
            </Link>
            <p className="text-secondary mb-2">
              Finden Sie den richtigen Makler für Ihre Immobilie!
            </p>
            <ul className="list-unstyled list-inline list-social mb-6 mb-md-0">
              <li className="list-inline-item list-social-item">
                <a
                  href="https://www.facebook.com/Maklerzentrale/"
                  target="_blank"
                  aria-label="Maklerzentrale auf Facebook"
                  className="text-decoration-none me-3"
                >
                  <Facebook fill="#506690" color="none" size={17} />{" "}
                  <span className="badge bg-success-soft arrow-left d-md-none d-lg-inline-block">
                    110
                  </span>
                </a>

                <Link
                  to="/de/bewertungen/"
                  title={`${avgRatings.toFixed(1)} Sterne aus ${
                    ratings.length
                  } Bewertungen`}
                  className=""
                >
                  <Rating
                    id="ratingStars"
                    initialRating={avgRatings}
                    readonly
                    emptySymbol={
                      <Star
                        style={{ color: "#ffb900" }}
                        width="17"
                        height="17"
                      />
                    }
                    fullSymbol={
                      <Star
                        style={{ color: "#ffb900" }}
                        fill="#ffb900"
                        width="17"
                        height="17"
                      />
                    }
                  />
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-3 order-2 order-sm-1">
            <h6 className="fw-bold text-uppercase text-secondary">
              Unternehmen
            </h6>
            <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
              <li className="mb-3">
                <Link to="/de/ueber-uns/" className="text-reset">
                  Über uns
                </Link>
              </li>
              <li className="mb-3">
                <Link to="/de/ueber-uns/#philosophie" className="text-reset">
                  Philosophie
                </Link>
              </li>
              <li className="mb-3">
                <Link to="/de/kontakt/" className="text-reset">
                  Kontakt
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-3 px-md-0 order-1 order-sm-2">
            <h6 className="fw-bold text-uppercase text-secondary">Services</h6>
            <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
              <li className="mb-3">
                <Link to="/de/maklerempfehlung/" className="text-reset">
                  Makler&shy;empfehlung
                </Link>
              </li>
              <li className="mb-3">
                <Link to="/de/immobilienbewertung/" className="text-reset">
                  Immobilien&shy;bewertung
                </Link>
              </li>
              <li>
                <Link to="/de/immobilie-verkaufen/" className="text-reset">
                  Immobilie verkaufen
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-md-3 order-3 order-sm-3">
            <h6 className="fw-bold text-uppercase text-secondary">
              Immobilienmakler
            </h6>
            <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
              <li className="mb-3">
                <Link
                  to="/de/immobilienpreise-deutschland/"
                  className="text-reset"
                >
                  Übersicht
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  to="/de/immobilienpreise-deutschland/#alle-regionen"
                  className="text-reset"
                >
                  Alle Regionen
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  to="/de/immobilienpreise-deutschland/#immobilienpreise"
                  className="text-reset"
                >
                  Ø Immobilien­preise
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="row my-4 justify-content-between fw-bold text-primary"
          style={{ fontSize: "13px" }}
        >
          <div className="col-md mb-2">
            © <span className="d-none d-sm-inline">Copyright</span> {year} -
            Maklerzentrale | Nahestraße 5 | 55118 Mainz
          </div>
          <div className="col-md-auto">
            <div className="d-inline-block">
              <Link
                to="/de/immobilienpreise-deutschland/"
                language="de"
                className="me-2"
                aria-label="Immobilienpreise Deutschland"
              >
                <DE
                  height="18px"
                  width="18px"
                  viewBox="0 0 640 480"
                  className="rounded"
                />
              </Link>
              <Link
                to="/de/immobilienmakler-oesterreich/"
                language="de"
                className="me-2"
                aria-label="Immobilienmakler Österreich"
              >
                <AT
                  height="18px"
                  width="18px"
                  viewBox="0 0 640 480"
                  className="rounded"
                />
              </Link>
              <Link
                to="/de/immobilienmakler-schweiz/"
                language="de"
                className="me-5"
                aria-label="Immobilienmakler Schweiz"
              >
                <CH
                  height="18px"
                  width="18px"
                  viewBox="0 0 640 480"
                  className="rounded"
                />
              </Link>
            </div>
            <Link to="/de/karriere/" className="text-gray-800">
              Karriere
            </Link>{" "}
            |{" "}
            <Link to="/de/impressum/" className="text-gray-800">
              Impressum
            </Link>{" "}
            |{" "}
            <Link to="/de/datenschutz/" className="text-gray-800">
              Datenschutz
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
