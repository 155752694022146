import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { ChevronDown, X } from "react-feather"
import Image from "./Image"
import Marker1 from "../svgs/icons/duotone-icons/Map/marker-1.svg"
import { useInView } from "react-intersection-observer"

import {
  Collapse,
  NavbarToggler,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"

import Home from "../svgs/icons/duotone-icons/Home/Home.svg"
import User from "../svgs/icons/duotone-icons/General/User.svg"
import ClipboardCheck from "../svgs/icons/duotone-icons/Communication/Clipboard-check.svg"

import ActiveCall from "../svgs/icons/duotone-icons/Communication/Active-call.svg"
import AddressBook from "../svgs/icons/duotone-icons/Communication/Adress-book-2.svg"

import Group from "../svgs/icons/duotone-icons/Communication/Group.svg"

import CallMenu from "./CallMenu"

const Header = props => {
  const {
    className = "",
    type,
    fixed = true,
    minimal = false,
    initBgColor = "transparent",
    initColor = "dark",
  } = props

  const bgColorOnTop = initBgColor
  const colorOnTop = initColor
  const bgColorOnScroll = "light"
  const colorOnScroll = "light"

  const [color, setColor] = useState(colorOnTop)
  const [bgColor, setBgColor] = useState(bgColorOnTop)

  const [respNavbarOpen, setRespNavbarOpen] = useState(false)
  const [dropdownRatgeberOpen, setDropdownRatgeberOpen] = useState(false)
  const [dropdownServiceOpen, setDropdownServiceOpen] = useState(false)

  function toggleDropdownRatgeber() {
    if(dropdownRatgeberOpen && respNavbarOpen){
      toggleRespNavbar()
    }
    setDropdownRatgeberOpen(!dropdownRatgeberOpen)
  }
  function toggleDropdownService() {
    if(dropdownServiceOpen && respNavbarOpen){
      toggleRespNavbar()
    }
    setDropdownServiceOpen(!dropdownServiceOpen)
  }

  const [ref, isScrollTop, entry] = useInView({
    threshold: 0,
  })

  useEffect(() => {
    setBgColor(isScrollTop || !fixed ? bgColorOnTop : bgColorOnScroll)
    setColor(isScrollTop || !fixed ? colorOnTop : colorOnScroll)
  }, [isScrollTop, props])

  function overflowHide() {
    const scrollbarWidth = getScrollbarWidth()

    document.documentElement.style.overflow = "hidden"
    document.body.style.paddingRight = scrollbarWidth + "px"
  }

  function overflowShow() {
    document.documentElement.style.overflow = ""
    document.body.style.paddingRight = ""
  }

  function getScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth
  }

  function toggleRespNavbar() {
    if (respNavbarOpen) {
      overflowShow()
    } else {
      overflowHide()
    }
    setRespNavbarOpen(!respNavbarOpen)
  }

  return (
    <>
      <span ref={ref} style={{ visibility: "hidden" }} id="forScrollTop" />
      <nav
        className={`navbar navbar-expand-lg navbar-togglable
        ${fixed ? "fixed-top" : ""} bg-${bgColor} navbar-${color} ${className}`}
      >
        <div className="header_underlay" />
        <div className={type === "boxed" ? `container-xl` : `container-fluid`}>
          <div className="toggle-text-shadow-custom">
            <Link className="navbar-brand" to="/de/">
              <Image
                src="/images/logos/maklerzentrale_fw.png"
                loadFrom="logo"
                style={{ height: "auto", width: "200px" }}
                className="navbar-brand-img"
                alt="Maklerzentrale"
              />
            </Link>
          </div>

          {!minimal && (
            <Collapse isOpen={respNavbarOpen} navbar style={{ zIndex: 1 }}>
              <NavbarToggler onClick={toggleRespNavbar} aria-label="Schließen">
                <X size={18} />
              </NavbarToggler>

              <Nav className="ms-auto" navbar>
                <Dropdown
                  isOpen={dropdownRatgeberOpen}
                  toggle={toggleDropdownRatgeber}
                  onMouseEnter={() => setDropdownRatgeberOpen(true)}
                  onMouseLeave={() => setDropdownRatgeberOpen(false)}
                  nav
                  inNavbar
                >
                  <DropdownToggle
                    nav
                    caret
                    className="toggle-text-shadow-custom pe-0"
                  >
                    Ratgeber <ChevronDown size={18} />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-md">
                    <div className="list-group list-group-flush">
                      <Link
                        to="/de/immobilie-verkaufen/"
                        className="list-group-item"
                        onClick={toggleDropdownRatgeber}
                      >
                        <div className="icon icon-sm text-primary">
                          <Home />
                        </div>
                        <div className="ms-4">
                          <h6 className="fw-bold text-uppercase text-primary mb-0">
                            Immobilie verkaufen
                          </h6>
                          <p className="fs-sm text-gray-700 mb-0">
                            Worauf Sie achten sollten
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="/de/makler-finden/"
                        className="list-group-item"
                        onClick={toggleDropdownRatgeber}
                      >
                        <div className="icon icon-sm text-primary">
                          <User />
                        </div>
                        <div className="ms-4">
                          <h6 className="fw-bold text-uppercase text-primary mb-0">
                            Versierten Makler finden
                          </h6>
                          <p className="fs-sm text-gray-700 mb-0">
                            Leitfaden und Empfehlungen
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="/de/immobilienpreise-deutschland/"
                        className="list-group-item"
                        onClick={toggleDropdownRatgeber}
                      >
                        <div className="icon icon-sm text-primary">
                          <Marker1 />
                        </div>
                        <div className="ms-4">
                          <h6 className="fw-bold text-uppercase text-primary mb-0">
                            Immobilienpreise in Deutschland
                          </h6>
                          <p className="fs-sm text-gray-700 mb-0">
                            Preisatlas & aktuelle Trends
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="/de/ueber-uns/"
                        className="list-group-item"
                        onClick={toggleDropdownRatgeber}
                      >
                        <div className="icon icon-sm text-primary">
                          <Group />
                        </div>
                        <div className="ms-4">
                          <h6 className="fw-bold text-uppercase text-primary mb-0">
                            Über die Maklerzentrale
                          </h6>
                          <p className="fs-sm text-gray-700 mb-0">
                            Beratungsstelle für Eigentümer
                          </p>
                        </div>
                      </Link>
                    </div>
                  </DropdownMenu>
                </Dropdown>

                <Dropdown
                  isOpen={dropdownServiceOpen}
                  toggle={toggleDropdownService}
                  onMouseEnter={() => setDropdownServiceOpen(true)}
                  onMouseLeave={() => setDropdownServiceOpen(false)}
                  nav
                  inNavbar
                >
                  <DropdownToggle
                    nav
                    caret
                    className="toggle-text-shadow-custom pe-0"
                  >
                    Service <ChevronDown size={18} />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-md">
                    <div className="list-group list-group-flush">
                      <Link
                        to="/de/immobilienbewertung/"
                        className="list-group-item"
                        onClick={toggleDropdownService}
                      >
                        <div className="icon icon-sm text-primary">
                          <ClipboardCheck />
                        </div>
                        <div className="ms-4">
                          <h6 className="fw-bold text-uppercase text-primary mb-0">
                            Immobilienbewertung
                          </h6>
                          <p className="fs-sm text-gray-700 mb-0">
                            Kostenfrei
                            <span className="badge rounded-pill bg-primary-soft float-end mt-1 ms-2 px-2">
                              vom Fachmann
                            </span>
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="/de/maklerempfehlung/"
                        className="list-group-item"
                        onClick={toggleDropdownService}
                      >
                        <div className="icon icon-sm text-primary">
                          <User />
                        </div>
                        <div className="ms-4">
                          <h6 className="fw-bold text-uppercase text-primary mb-0">
                            Maklerempfehlung
                          </h6>
                          <p className="fs-sm text-gray-700 mb-0">
                            Besser verkaufen mit Makler
                          </p>
                        </div>
                      </Link>
                      <a
                        href="https://verzeichnis.maklerzentrale.com/makler-finden/"
                        className="list-group-item"
                      >
                        <div className="icon icon-sm text-primary">
                          <AddressBook />
                        </div>
                        <div className="ms-4">
                          <h6 className="fw-bold text-uppercase text-primary mb-0">
                            Maklerverzeichnis
                          </h6>
                          <p className="fs-sm text-gray-700 mb-0">
                            Über 20.000 Immobilienmakler
                          </p>
                        </div>
                      </a>
                      <Link
                        to="/de/kontakt/"
                        className="list-group-item"
                        onClick={toggleDropdownService}
                      >
                        <div className="icon icon-sm text-primary">
                          <ActiveCall />
                        </div>
                        <div className="ms-4">
                          <h6 className="fw-bold text-uppercase text-primary mb-0">
                            Kontakt
                          </h6>
                          <p className="fs-sm text-gray-700 mb-0">
                            Wir beraten Sie gerne
                          </p>
                        </div>
                      </Link>
                    </div>
                  </DropdownMenu>
                </Dropdown>
                {/*<NavItem className="dropdown">*/}
                {/*  <Link*/}
                {/*    to="/de/ueber-uns/"*/}
                {/*    className="nav-link toggle-text-shadow-custom"*/}
                {/*    onClick={toggleRespNavbar}*/}
                {/*  >*/}
                {/*    Über uns*/}
                {/*  </Link>*/}
                {/*</NavItem>*/}
              </Nav>

              <div className="ms-auto">
                <Link
                  to="/de/maklerempfehlung/"
                  className="navbar-btn d-md-none btn btn-sm btn-primary"
                  onClick={toggleRespNavbar}
                >
                  Makler finden
                </Link>
                <Link
                  to="/de/immobilienbewertung/"
                  className="navbar-btn d-md-none btn btn-sm btn-primary-desat-soft"
                  onClick={toggleRespNavbar}
                >
                  Immobilienbewertung
                </Link>
              </div>
            </Collapse>
          )}

          {!minimal && (
            <div className="outer-cta-btns ms-auto">
              <Link
                to="/de/maklerempfehlung/"
                style={{ lineHeight: "18px", fontSize: "16px" }}
                className="d-none d-md-inline-block btn btn-sm btn-primary py-3 px-2 px-lg-3 me-1 me-lg-2"
              >
                Makler finden
              </Link>
              <Link
                to="/de/immobilienbewertung/"
                style={{ lineHeight: "18px", fontSize: "16px" }}
                className="d-none d-md-inline-block btn btn-sm btn-primary-desat-soft py-3 px-2 px-lg-3 toggle-text-shadow-custom"
              >
                Immobilienbewertung
              </Link>
            </div>
          )}

          <div className="ms-auto ms-lg-3 ms-xl-5 text-center">
            {/* SERPs wrong indexed */}
            {typeof window !== "undefined" &&
              !/bot|google|baidu|bing|msn|teoma|slurp|yandex/i.test(
                navigator.userAgent
              ) && <CallMenu />}
          </div>

          {!minimal && (
            <NavbarToggler
              onClick={toggleRespNavbar}
              aria-label="Öffnen"
              className="toggle-text-shadow-custom"
            />
          )}
        </div>
      </nav>
    </>
  )
}

export default Header
