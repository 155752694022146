/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"
import { LayoutProvider } from "./src/components/LayoutContext"
import { GTMOnRouteUpdate } from "./src/components/GTM"

const currentEnvironment = process.env.ENV || process.env.NODE_ENV

const isEnvironmentValid = environments =>
  environments.includes(currentEnvironment)

function isLeadGeneratorStep(pathname) {
  const generatorInitPaths = [
    "makler",
    "wohnung",
    "haus",
    "gewerbe",
    "grundstueck",
  ]
  // use for loop instead of forEach to "return false" (async)
  for (let i = 0; i < generatorInitPaths.length; i++) {
    if (pathname.includes(`/${generatorInitPaths[i]}/`)) {
      return true
    }
  }
  return false
}

// with useLocation() access
export const wrapPageElement = ({ element, props }) => (
  <LayoutProvider {...props}>{element}</LayoutProvider>
)

// If you support Safari (older versions) and/or Internet Explorer, you have to install the IntersectionObserver polyfill.
// https://www.gatsbyjs.org/packages/gatsby-background-image/
export const onClientEntry = async () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE) and Co
  if (!("IntersectionObserver" in window)) {
    await import("intersection-observer")
    // console.log(`# IntersectionObserver is polyfilled!`)
  }
  return true
}

// keep lead generators browser history
export const shouldUpdateScroll = ({
  prevRouterProps,
  pathname,
  routerProps: { location },
}) => {
  // console.log("shouldUpdateScroll")

  if (isLeadGeneratorStep(pathname)) {
    // console.log(
    //   "no scroll update - is lead generator step forwards or backwards"
    // )
    return false
  }
}

// https://github.com/andrezimpel/gatsby-plugin-gdpr-cookies/blob/master/src/gatsby-browser.js
// runs also after page init
export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log("onRouteUpdate")

  GTMOnRouteUpdate(location)
}
