import React, { useEffect, useState } from "react"
import { withCookies } from "react-cookie"
import { useLocation } from "@reach/router"
import Loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
import * as dayjs from "dayjs"

import Header from "./Header"
import Footer from "./Footer"

const GTM = Loadable(() => pMinDelay(import("./GTM"), 1)) //todo: back to 3000
// const CrispChat = Loadable(() => pMinDelay(import("./CrispChat"), 5100))
// const Smartlook = Loadable(() => pMinDelay(import("./Smartlook"), 5100))

// Theme CSS
// import "../assets/scss/libs.scss"
import "../assets/scss/theme.scss"

export const LayoutContext = React.createContext([{}, () => {}])

export const LayoutProvider = withCookies(({ cookies, children }) => {
  const [layout, setLayout] = useState({
    header: {},
    hide: false,
    stopTrack: false,
  })

  const { header, hide, stopTrack } = layout

  const mzCookies = cookies.get("maklerzentrale") || {}
  const notrackCookie = cookies.get("notrack")
  const isServer = typeof window === "undefined"

  // const smartlookEnabled = !!+process.env.GATSBY_ENABLE_SMARTLOOK
  // const crispEnabled = !!+process.env.GATSBY_ENABLE_CRISP
  const gtmEnabled = !!+process.env.GATSBY_ENABLE_GTM

  const location = useLocation()

  useEffect(() => {
    if (location.search === "?notrack") {
      cookies.set("notrack", true, {
        path: "/",
        expires: new Date(dayjs().add(12, "M").format()),
        secure: true,
      })
    }
  }, [])

  return (
    <LayoutContext.Provider value={[layout, setLayout]}>
      {notrackCookie && !isServer ? (
        <div
          className="bg-success w-100 position-fixed"
          style={{ height: "5px", zIndex: "10000" }}
        />
      ) : null}

      {/*{!notrackCookie &&*/}
      {/*!isServer &&*/}
      {/*!stopTrack &&*/}
      {/*smartlookEnabled &&*/}
      {/*mzCookies.statistics ? (*/}
      {/*  <Smartlook />*/}
      {/*) : null}*/}

      {/*{!notrackCookie &&*/}
      {/*!isServer &&*/}
      {/*!hide &&*/}
      {/*mzCookies.thirdParty &&*/}
      {/*crispEnabled ? (*/}
      {/*  <CrispChat />*/}
      {/*) : null}*/}

      {!notrackCookie && !isServer && !stopTrack && gtmEnabled ? (
        <GTM location={location} />
      ) : null}

      {!hide && (
        <Header
          className={header.className}
          type="boxed"
          minimal={header.minimal}
          fixed={header.fixed}
          initBgColor={header.bgColor}
          initColor={header.color}
        />
      )}

      {children}

      {!hide && <Footer />}
    </LayoutContext.Provider>
  )
})

export default LayoutContext
