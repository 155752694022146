import React, { useRef, useEffect, useState } from "react"
import { Phone, X } from "react-feather"
import { Input, Dropdown, DropdownMenu, FormGroup, Label } from "reactstrap"
import axios from "axios"
import Alert from "react-bootstrap/Alert"
import { sendConversion } from "./GTM"
import { useLocation } from "@reach/router"

const CallMenu = () => {
  const location = useLocation()

  const [callbackName, setCallbackName] = useState("")
  const [callbackPhone, setCallbackPhone] = useState("")
  const [callbackMessage, setCallbackMessage] = useState("")
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const wrapperRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setDropdownOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef])

  function toggleDropdown() {
    setDropdownOpen(!dropdownOpen)
  }

  const currentDate = new Date()
  // todo: Montag bis Freitag + UTC?
  const openedDate = new Date().setHours(9, 0, 0) // 9.00 Uhr
  const closedDate = new Date().setHours(20, 0, 0) // 20.00 Uhr

  const isPhoneReachable =
    currentDate >= openedDate &&
    currentDate < closedDate &&
    currentDate.getDay() !== 0 // sunday: 0

  function callClicked(e) {
    if (!isPhoneReachable) {
      e.preventDefault()
      toggleDropdown()
    } else {
      // disabled: fake conversions counted
      // sendConversion("AW-641416186/hJh3CJ3lhNEBEPr37LEC") // Website-Mobil-Anrufe
    }
  }

  const handleSubmit = event => {
    event.preventDefault()

    setIsLoading(true)
    sendConversion("callback_service")
    axios
      .post("/.netlify/functions/send_mail", {
        callbackName,
        callbackPhone,
        callbackMessage,
        ref: location.ref,
      })
      .then(response => {
        // console.log(response)
        setShowSuccessAlert(true)
        // clear vars
        setCallbackName("")
        setCallbackPhone("")
        setCallbackMessage("")
      })
      .catch(error => {
        console.log(error)
        alert(
          "Leider ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut oder schreiben Sie uns eine E-Mail."
        )
      })
      .then(() => {
        setIsLoading(false)
      })
  }

  return (
    <div ref={wrapperRef}>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
        inNavbar
        className="position-static position-sm-relative" /* Dropdown CallMenu responsive */
      >
        <a href="tel:+498002242020" onClick={callClicked} className="call-btn">
          <Phone size={18} className="me-1" />
          <span className="d-none d-sm-inline fw-bold toggle-text-shadow-custom">
            {isPhoneReachable ? "0800 2242020" : "Rückruf-Service"}
          </span>
        </a>
        <div className="d-none d-sm-block fw-bold toggle-text-shadow-custom">
          Wir sind für Sie da!
        </div>

        <DropdownMenu
          className="dropdown-menu-md"
          style={{ left: "unset", right: "0" }}
        >
          <button
            type="button"
            className="btn btn-link p-0"
            onClick={toggleDropdown}
            style={{
              position: "absolute",
              top: "1.625rem",
              right: "1rem",
              zIndex: "1",
            }}
          >
            <X />
          </button>

          <h3>Sie haben eine Frage?</h3>

          <h4>Rückruftermin vereinbaren</h4>
          <p>Wir sind täglich von 9:00 bis 20:00 Uhr für Sie da.</p>

          {showSuccessAlert ? (
            <Alert
              variant="success"
              onClose={() => setShowSuccessAlert(false)}
              dismissible
              className="mb-5"
            >
              Vielen Dank für Ihre Anfrage! Wir werden uns in Kürze bei Ihnen
              melden.
            </Alert>
          ) : null}

          <form onSubmit={handleSubmit}>
            <div className="form-group form-floating">
              <input
                type="text"
                className="form-control form-control-flush"
                id="callbackName"
                value={callbackName}
                onChange={e => setCallbackName(e.target.value)}
                required
                placeholder="Ihr Name"
              />
              <label htmlFor="callbackName">Ihr Name</label>
            </div>
            <div className="form-group form-floating">
              <input
                type="text"
                className="form-control form-control-flush"
                id="callbackPhone"
                value={callbackPhone}
                onChange={e => setCallbackPhone(e.target.value)}
                required
                placeholder="Ihre Telefonnummer"
              />
              <label htmlFor="callbackPhone">Ihre Telefonnummer</label>
            </div>
            <div className="form-group form-floating">
              <input
                type="text"
                className="form-control form-control-flush"
                id="callbackMessage"
                value={callbackMessage}
                onChange={e => setCallbackMessage(e.target.value)}
                placeholder="Ihre Erreichbarkeit"
              />
              <label htmlFor="callbackMessage">Ihre Erreichbarkeit</label>
            </div>
            <div className="mt-6">
              <FormGroup check>
                <Input
                  type="checkbox"
                  id="zustimmungDatenschutzRueckruf"
                  name="zustimmungDatenschutzRueckruf"
                  className="mb-3 mb-sm-0"
                  required
                />
                <Label check for="zustimmungDatenschutzRueckruf">
                  <span
                    className="text-gray-600 d-inline-block"
                    style={{ fontSize: "12px" }}
                  >
                    Ich stimme den{" "}
                    <a
                      href="/de/datenschutz/"
                      target="_blank"
                      className="text-muted"
                    >
                      <u>Datenschutz­bestimmungen</u>
                    </a>{" "}
                    und einer Kontakt­aufnahme durch die Maklerzentrale per
                    E-Mail oder Telefon für Rückfragen oder zu
                    Informations­zwecken zu.
                  </span>
                </Label>
              </FormGroup>
              <button
                type="submit"
                className="btn btn-block btn-success lift"
                disabled={isLoading}
              >
                {isLoading && (
                  <span
                    className="spinner-grow spinner-grow-sm me-2"
                    role="status"
                    aria-hidden="true"
                  />
                )}{" "}
                Jetzt Rückruf vereinbaren
              </button>
            </div>
          </form>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default CallMenu
